<template>
  <div class="password-input">
    <exc-input
      :type="inputType"
      v-bind="{ ...$attrs }"
      :value="value"
      class="password-input__input"
      @input="inputHandler"
    />
    <div
      class="password-input__icon"
      @click="toggleType"
    >
      <hide-icon v-if="showValue" />
      <show-icon
        v-else
        class="txt--main"
      />
    </div>
    <div
      v-if="isCopy"
      class="password-input__icon-copy"
      @click="copy(value)"
    >
      <copy-icon />
    </div>
  </div>
</template>

<script>
import { ExcInput } from '@/components/common'
import { copyToClipboard } from '@/lib/utils'

export default {
  name: 'PasswordInput',
  components: {
    ExcInput,
    HideIcon: () => import(/* webpackMode: "eager" */ '@/assets/icons/trade/hide-pairs.svg?inline'),
    ShowIcon: () => import(/* webpackMode: "eager" */ '@/assets/icons/trade/show-pairs.svg?inline'),
    CopyIcon: () => import(/* webpackMode: "eager" */ '@/assets/icons/copy.svg?inline'),
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    isCopy: {
      type: Boolean,
      required: false,
    },
  },
  data () {
    return {
      showValue: true,
    }
  },
  computed: {
    inputType () {
      return this.showValue ? 'password' : 'text'
    },
  },
  methods: {
    toggleType () {
      this.showValue = !this.showValue
    },
    inputHandler (value) {
      this.$emit('input', value)
    },
    copy (value) {
      copyToClipboard(value).then(
        () => {
          this.$notify({
            // group: "info",
            type: 'success',
            title: this.$t('common.notify.copied'),
            text: this.textSuccess,
          })
        },
        () => {
          this.$notify({
            // group: "error",
            type: 'error',
            title: this.$t('common.notify.uhOh'),
            text: this.$t('common.notify.somethingWentWrong'),
          })
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.password-input {
  position: relative;

  &__icon {
    position: absolute;
    cursor: pointer;
    right: toRem(16px);
    bottom: toRem(-3px);
    transform: translateY(-50%);
  }

  &__icon-copy {
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: pointer;
  }

  &__input {
    &::v-deep {
      input {
        padding-right: toRem(45px);
      }
    }
  }

  @include bp(0, l) {
    ::v-deep .tdx-base-input{
      padding-right: toRem(100px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
